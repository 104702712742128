<template>
  <b-overlay :show="showOverlay" rounded="sm">
    <b-card class="share_box rounded-xl">
      <h4 class="text-terciary mb-3">
        {{ $t("teams.share_something") }}
      </h4>
      <div class="inputWrapper">
        <b-form-textarea
          rows="3"
          no-resize
          v-model="communicationText"
          :placeholder="$t('teams.write_something')"
          ref="CommnicationTextRef"
          class="m-0 pl-4 pr-5 py-3 form-control-sm text-terciary font-weight-normal rounded"
          style="overflow:auto"
        ></b-form-textarea>

        <!-- EmojiPicker -->
        <EmojiComponent v-on:append="appendCommunicationEmoji($event)" />
      </div>
      <div class="py-3 d-flex align-items-center">
        <b-button
          class="m-0 p-0 btn shadow-none"
          style="background:transparent;border:none"
          @click="PickFile"
        >
          <b-icon-camera
            class="text-muted mr-3 mb-3"
            font-scale="1.5"
          ></b-icon-camera>
        </b-button>

        <!-- File Input -->
        <input
          type="file"
          style="display:none"
          ref="InputFile"
          accept="video/mp4,video/x-m4v,video/*,image/*"
          @change="uploadFile"
        />

        <span v-if="SelectedFile && fileUploaded" class="text-primary">{{
          SelectedFile.name
        }}</span>
        <b-button
          type="submit"
          class="px-5 py-2 ml-auto rounded"
          variant="primary"
          id="show-btn"
          :disabled="spinnerLoading"
          v-if="!spinnerLoading"
          @click="CreateCommunication()"
        >
          <span v-if="!spinnerLoading">{{ $t("teams.publish") }}</span>
          <b-spinner small v-if="spinnerLoading"></b-spinner>
        </b-button>
      </div>
      <h4 class="text-terciary mb-3 mt-4">
        {{ $t("teams.latest_chats") }}
      </h4>
      <b-overlay :show="loading">
        <div v-if="TeamCommunication.length">
          <div>
            <!-- Individual Communications -->
            <div
              v-for="(communication, index) in TeamCommunication"
              :key="communication.id"
              class="w-100"
            >
              <div class="d-flex align-items-start">
                <b-img
                  class="mr-2 mb-3 rounded-circle"
                  style="object-fit: cover;"
                  height="45"
                  width="45"
                  v-if="
                    communication.userprofile.avatar_file_path &&
                      communication.userprofile.avatar_file_name
                  "
                  :src="
                    `${communication.userprofile.avatar_file_path}${communication.userprofile.avatar_file_name}`
                  "
                  alt="img"
                />
                <b-avatar
                  v-else
                  style="background:#E63375"
                  class="mr-2 mb-3"
                  :text="communication.userprofile.initials"
                  size="45"
                ></b-avatar>

                <div class="w-100">
                  <b-row>
                    <b-col cols="12" md="12" lg="8">
                      <div class="d-flex align-items-start">
                        <span
                          class="text-terciary mt-2 mb-2 form-control-md mr-3 font-weight-semibold"
                          >{{ communication.userprofile.full_name }}</span
                        >
                        <span
                          class="form-control-sm text-muted font-weight-normal mt-1"
                        >
                          <b-icon-clock class="mr-1"></b-icon-clock>                        
                          {{
                            dateDiffDays(new Date(), new Date(communication.created_dt)) - 1 ||
                              ""
                          }}
                          {{
                            `${$tc(
                              "days_ago",
                              dateDiffDays(new Date(), new Date(communication.created_dt)) - 1
                            )}`.toLocaleLowerCase()
                          }}                          
                        </span>
                        <b-button
                          v-if="communication.posted_by_me === 1"
                          class="m-0 p-0 ml-2 btn shadow-none"
                          style="background:transparent;border:none"
                          @click="deleteCommunication(communication.id)"
                        >
                          <b-icon-trash-fill
                            class="text-danger"
                          ></b-icon-trash-fill>
                        </b-button>
                      </div>
                    </b-col>
                    <b-col
                      cols="12"
                      md="12"
                      lg="4"
                      class="d-flex justify-content-end mb-2 mb-md-0"
                    >
                      <!-- Reactions -->
                      <div>
                        <b-button
                          v-for="reaction in allReactions"
                          :key="reaction.reaction_id"
                          class="m-0 p-0 mr-2 btn shadow-none"
                          style="background:transparent;border:none"
                          @click="
                            createReaction(
                              communication.id,
                              reaction.reaction_id
                            )
                          "
                        >
                          <span class="emoji-size">
                            {{ reaction.reaction }}
                          </span>
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <div class=" ">
                <!-- If Media File is not null -->
                <div v-if="communication.mediafile" class="media-box">
                  <b-img-lazy
                    class=" mb-3 mt-3"
                    v-if="communication.mediafile.media_type_cd === 'IMG'"
                    style="object-fit: cover;width:100%;"
                    :src="
                      `${communication.mediafile.file_path}${communication.mediafile.file_name}`
                    "
                    alt="img"
                  />

                  <div
                    v-if="communication.mediafile.media_type_cd === 'MOV'"
                    class="mb-3"
                  >
                    <video
                      ref="videoRef"
                      :src="
                        `${communication.mediafile.file_path}${communication.mediafile.file_name}`
                      "
                      id="video-container"
                      width="100%"
                      controls
                    ></video>
                  </div>
                </div>

                <!-- Communication Text -->
                <p
                  class="text-terciary font-weight-normal mt-1 mb-3"
                  style="white-space: pre-line;"
                >
                  {{ communication.text }}
                </p>

                <div
                  class="d-flex align-items-center mb-3"
                  v-if="communication.reactions.length"
                >
                  <div
                    v-for="reaction in communication.reactions"
                    :key="reaction.reaction_id"
                    class="mr-1 py-1 pl-1  d-flex justify-content-center align-items-center"
                    style="background:#eeeeee;border-radius:50%; "
                  >
                    <b-button
                      class="m-0 p-0  btn shadow-none "
                      style="background:transparent;border:none"
                      @click="
                        deleteReaction(
                          reaction.communication_id,
                          reaction.reaction_id
                        )
                      "
                      :id="`${communication.id}-${reaction.reaction_id}`"
                    >
                      <span class="emoji-size"> {{ reaction.reaction }}</span>
                    </b-button>

                    <b-popover
                      :target="`${communication.id}-${reaction.reaction_id}`"
                      triggers="hover"
                      class="my-tooltip"
                      placement="bottomright"
                      variant="primary"
                    >
                      <template #title>
                        {{ $t("teams.reacted_users") }}
                      </template>
                      <Tooltip
                        :communication_id="communication.id"
                        :reaction_id="reaction.reaction_id"
                      />
                    </b-popover>

                    <span
                      class="text-terciary font-weight-normal mr-2 emoji-count"
                      >{{ reaction.reactions_count }}</span
                    >
                  </div>
                </div>

                <div class="inputWrapper">
                  <b-form-textarea
                    size="sm"
                    v-model="communicationComment[index]"
                    no-resize
                    ref="CommunicationCommentRef"
                    :value="communicationComment[index]"
                    :disabled="keyPressDisable"
                    v-on:keyup.enter="createComment(communication.id, index)"
                    :placeholder="$t('teams.add_comments')"
                    class="m-0 pl-4 pr-5 py-2 form-control-sm text-primary font-weight-normal rounded-pill small_textarea"
                  ></b-form-textarea>

                  <EmojiComponent
                    v-on:CommentAppend="appendCommentEmoji($event, index)"
                  />
                </div>

                <div class="mt-3 ">
                  <!-- Individual Comments  -->
                  <div
                    class="mt-2"
                    v-for="comment in communication.comments"
                    :key="comment.comment_id"
                  >
                    <div class="d-flex align-items-start ">
                      <b-img
                        class="mr-3 mt-2 rounded-circle"
                        style="object-fit: cover;"
                        height="30"
                        width="30"
                        v-if="
                          comment.avatar_file_path && comment.avatar_file_name
                        "
                        :src="
                          `${comment.avatar_file_path}${comment.avatar_file_name}`
                        "
                        alt="Image"
                      />
                      <b-avatar
                        v-else
                        style="background:#999999"
                        size="30"
                        class="mr-3 mt-2"
                      >
                        <small>{{ comment.initials }}</small>
                      </b-avatar>
                      <div>
                        <div class="d-flex  align-items-start">
                          <span
                            class="text-terciary mt-2 mb-2 form-control-md mr-3 font-weight-semibold"
                            >{{ comment.full_name }}</span
                          >
                          <span
                            class="form-control-sm text-light font-weight-normal"
                          >
                            <b-icon-clock class="mr-1"></b-icon-clock>
                            {{ comment.created_dt }}
                          </span>
                          <b-button
                            v-if="comment.commented_by_me === 1"
                            class="m-0 p-0 ml-2 btn shadow-none"
                            style="background:transparent;border:none"
                            @click="deleteComment(comment.comment_id)"
                          >
                            <b-icon-trash-fill
                              class="text-danger"
                            ></b-icon-trash-fill>
                          </b-button>
                        </div>
                        <p class="text-terciary font-weight-normal mt-1 mb-2">
                          {{ comment.comment }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- </div> -->
              <div class="share_box_border mt-3 mb-4"></div>
            </div>

            <!-- Functions trigger when user reaches bottom of the div -->
            <div
              v-if="TeamCommunication.length"
              v-observe-visibility="ScrolledToBottom"
            ></div>
          </div>
        </div>

        <div v-else class="text-center">
          <h6 class="text-primary">{{ $t("teams.no_communications") }}</h6>
        </div>
      </b-overlay>
    </b-card>
  </b-overlay>
</template>

<script>
import axios from "axios"
import EmojiComponent from "@/components/Checkout_Team/EmojiComponent.vue"
import Tooltip from "@/components/Checkout_Team/Tooltip.vue"
import { BIconCamera, BIconTrashFill, BIconClock } from "bootstrap-vue"
export default {
  props: ["teamCommunication", "teamId"],
  components: {
    Tooltip,
    EmojiComponent,
    BIconCamera,
    BIconTrashFill,
    BIconClock,
  },
  data() {
    return {
      loading: false,
      TeamCommunication: [],
      TeamCommunicationLink: null,
      showOverlay: false,
      spinnerLoading: false,
      allReactions: [],
      communicationText: "",
      communicationComment: [],
      SelectedFile: null,
      media_file_id: null,
      fileUploaded: false,
      tempCommunicationData: null,
      keyPressDisable: false,
    }
  },
  methods: {
    PickFile() {
      this.$refs.InputFile.click()
    },

    ConvertObjToArray(arr) {
      return arr.map(function(obj) {
        return JSON.stringify(obj)
      })
    },

    dateDiffDays(date1, date2) {
      return Math.ceil(Math.abs(date1 - date2) / (1000 * 60 * 60 * 24))
    },  
    
    async getTeamCommunications(pageURL, isPaginated) {
      this.loading = true
      const teamId = this.teamId

      let data = {
        communication_key: `TEAM_${teamId}`,
      }

      let url = `/communication`
      if (pageURL) {
        url = pageURL
      }
      try {
        const response = await axios.get(url, { params: data })

        if (response.status === 200) {
          if (isPaginated) {
            this.TeamCommunication = [
              ...this.TeamCommunication,
              ...response.data.data,
            ]
          } else {
            if (this.tempCommunicationData) {
              this.TeamCommunication = [
                ...[response.data.data[0]],
                ...this.TeamCommunication,
              ]
            } else {
              this.TeamCommunication = response.data.data
            }
          }
          if (!this.TeamCommunicationLink) {
            this.TeamCommunicationLink = response.data?.links ?? null
          }
          if (isPaginated) {
            this.TeamCommunicationLink = response.data?.links ?? null
          }
        }
      } catch (error) {
        if (error.response) {
          this.$store.dispatch("addToastMessage", {
            type: "danger",
            text: error.response.data.error_message,
          })
        } else {
          this.$store.dispatch("addToastMessage", {
            type: "danger",
            text: this.$t("error_occurred"),
          })
        }
      } finally {
        if (this.tempCommunicationData) this.tempCommunicationData = null
        this.loading = false
      }
    },

    async uploadFile(event) {
      var input = event.target
      if (input.files && input.files[0]) {
        this.SelectedFile = input.files[0]

        try {
          let data = {
            filename: this.SelectedFile.name,
            context_type: "COMM",
          }

          const response = await axios.post("/mediaobject", data)

          if (response.status === 200) {
            this.fileUploaded = true
            this.media_file_id = response.data?.data.media_file_id

            const save_URL = response.data?.data.save_url
            // Method to call S3 upload Put Request
            this.uploadFileToS3(save_URL)
          }
        } catch ({ response }) {
          this.$store.dispatch("addToastMessage", {
            type: "danger",
            text: response.data.error_message,
          })
        }
      }
    },
    async uploadFileToS3(url) {
      this.showOverlay = true
      try {
        // Should we really mix axios and fetch?
        await fetch(url, {
          method: "PUT",
          body: this.SelectedFile,
          headers: {
            "Content-Type": this.SelectedFile.type,
          },
        })
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.showOverlay = false
      }
    },

    async getAllReactions() {
      try {
        const response = await axios.get(`/reactions`)
        this.allReactions = response.data?.data ?? []
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      }
    },

    async CreateCommunication() {
      this.spinnerLoading = true
      try {
        let data = {
          communication_text: this.communicationText,
          communication_key: `TEAM_${this.teamId}`,
        }
        if (this.media_file_id) {
          data["media_file_id"] = this.media_file_id
        }
        const response = await axios.post("/communication", data)
        if (response.status === 200) {
          this.communicationText = ""
          this.SelectedFile = null
          this.media_file_id = null
          this.fileUploaded = false
          this.tempCommunicationData = data
          this.getTeamCommunications()
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.spinnerLoading = false
      }
    },
    async deleteCommunication(communication_id) {
      try {
        let data = {
          communication_id: communication_id,
        }

        const response = await axios.delete("/communication", {
          params: data,
        })

        if (response.status === 204) {
          this.filterComminucation(communication_id)
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      }
    },

    filterComminucation(id) {
      const filteredCommunication = this.TeamCommunication.filter(
        communication => communication.id !== id
      )
      this.TeamCommunication = filteredCommunication
    },

    async createReaction(communication_id, reaction_id) {
      try {
        let data = {
          communication_id: communication_id,
          reaction_id: reaction_id,
        }

        const response = await axios.post("/communicationreaction", data)

        if (response.status === 204) {
          this.getTeamCommunications()
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      }
    },
    async deleteReaction(communication_id, reaction_id) {
      try {
        let data = {
          communication_id: communication_id,
          reaction_id: reaction_id,
        }

        const response = await axios.delete("/communicationreaction", {
          params: data,
        })

        if (response.status === 204) {
          this.getTeamCommunications()
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      }
    },

    async createComment(communication_id, index) {
      this.keyPressDisable = true
      try {
        let data = {
          comment: this.communicationComment[index],
          communication_id: communication_id,
        }
        const response = await axios.post("/communicationcomment", data)

        if (response.status === 200) {
          this.communicationComment[index] = ""
          this.getTeamCommunications()
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.keyPressDisable = false
      }
    },

    async deleteComment(comment_id) {
      try {
        let data = {
          comment_id: comment_id,
        }

        const response = await axios.delete("/communicationcomment", {
          params: data,
        })

        if (response.status === 204) {
          this.getTeamCommunications()
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      }
    },
    // Add Emoji to the main Communication Input
    appendCommunicationEmoji(emoji) {
      let cursorPos = this.$refs.CommnicationTextRef.selectionStart

      this.communicationText =
        this.communicationText.slice(0, cursorPos) +
        emoji +
        this.communicationText.slice(cursorPos)
    },

    // Add Emoji to comments
    appendCommentEmoji(emoji, index) {
      if (!this.communicationComment[index])
        this.communicationComment[index] = ""
      let cursorPos = this.$refs.CommunicationCommentRef[index].selectionStart
      let newValue =
        this.communicationComment[index].slice(0, cursorPos) +
        emoji +
        this.communicationComment[index].slice(cursorPos)

      this.$set(this.communicationComment, index, newValue)
    },

    ScrolledToBottom(isVisible) {
      if (!isVisible) {
        return
      }
      if (this.TeamCommunicationLink.more_pages) {
        this.getTeamCommunications(
          this.TeamCommunicationLink.next_page_url,
          true
        )
      }
    },
  },
  mounted() {
    if (this.TeamCommunication.length) {
      this.TeamCommunication.forEach(index => {
        this.communicationComment[index] = ""
      })
    }
  },

  created() {
    this.getTeamCommunications()
    this.getAllReactions()
  },


}
</script>

<style scoped>
/* Share box */
.share_box {
  box-shadow: 0px 3px 20px #2422611c;
  min-height: 350px;
}
.share_box_border {
  border-bottom: 1px solid #d2d2d2;
}
.share_box >>> .small_textarea {
  height: 40px;
}

.inputWrapper {
  position: relative;
}
.media-box {
  margin-left: -20px;
  margin-right: -20px;
}

.emoji-size {
  font-size: 14px;
}
@media (min-width: 550px) {
  .emoji-size {
    font-size: 16px;
  }
}
</style>
