<template>
  <div>
    <b-button
      class="m-0 p-0 btn shadow-none"
      style="background:transparent;border:none"
      @click="modalShow = !modalShow"
    >
      <b-icon-x-circle-fill
        variant="danger"
        font-scale="1.5"
      ></b-icon-x-circle-fill>
    </b-button>
    <b-modal v-model="modalShow" centered hide-footer hide-header size="md">
      <b-container fluid class="p-4">
        <b-row align-v="center" class="">
          <b-col cols="12">
            <p class="text-terciary font-weight-bold">
              {{ $t("teams.confirm_remove_member") }}
            </p>
          </b-col>

          <div class="d-flex w-100 justify-content-end">
            <b-button
              variant="outline-primary"
              class=""
              @click="modalShow = !modalShow"
              style="border-radius:11px"
            >
              {{ $t("cancel") }}
            </b-button>

            <div class="ml-5">
              <b-button
                variant="primary"
                v-if="!spinnerLoading"
                style="border-radius:11px"
                @click="RemoveTeamMember"
              >
                Yes, {{ $t("remove").toLocaleLowerCase() }}
              </b-button>
              <b-button
                variant="primary"
                disabled
                v-if="spinnerLoading"
                style="border-radius:11px"
              >
                <b-spinner small></b-spinner>
                <span class="sr-only">Loading...</span>
              </b-button>
            </div>
          </div>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios"
import { BIconXCircleFill } from "bootstrap-vue"
export default {
  name: "RemoveUserModal",
  props: ["id"],
  components: { BIconXCircleFill },
  data() {
    return {
      modalShow: false,
      spinnerLoading: false,
    }
  },

  computed: {},

  methods: {
    async RemoveTeamMember() {
      this.spinnerLoading = true

      try {
        let data = {
          user_id: this.id,
        }

        const response = await axios.delete("/team/leave", {
          params: data,
        })
        if (response.status == 200) {
          // Close Modal
          this.modalShow = !this.modalShow
          this.$emit("getTeam")
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.spinnerLoading = false
      }
    },
  },
}
</script>
