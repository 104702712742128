<template>
  <b-card class="mb-5 activity_box rounded-xl">
    <b-row class="px-3 py-1" align-v="center" align-h="between">
      <h4 class="text-terciary m-0">{{ $t("teams.activity_feed") }}</h4>
      <b-button
        v-b-toggle.activity-collapse
        class="m-0 p-0 btn shadow-none"
        style="background:transparent;border:none"
      >
        <!-- <b-img src="@/assets/arrow_up.png" alt="Arrow Up" /> -->
        <b-icon-chevron-down class="text-primary"></b-icon-chevron-down>
      </b-button>
    </b-row>
    <div class="activity_box_border pt-3"></div>
    <b-overlay :show="loading">
      <b-collapse visible id="activity-collapse" class="collapse-container">
        <div
          v-if="TeamActivity.length"
          id="activity_wrapper"
          ref="scrollComponent"
        >
          <div class="" v-for="(activity, index) in TeamActivity" :key="index">
            <div class=" d-flex pt-4 pb-2 px-3">
              <b-img
                class="rounded-circle mr-3 mb-3"
                style="object-fit: cover;"
                height="29"
                width="29"
                v-if="activity.file_path && activity.file_name"
                :src="`${activity.file_path}${activity.file_name}`"
                @error="
                  event => {
                    event.target.src = $images.noAvatar
                  }
                "
                alt="Image"
              />
              <!-- If file_path & file_name is null -->
              <b-avatar
                v-else
                style="background:#00C782"
                class="mr-3"
                :text="activity.initials"
                size="29"
              ></b-avatar>
              <div class="text-box">
                <span class="mb-1 text-terciary font-weight-semibold">
                  {{ activity.full_name }}
                  <span class="text-terciary" v-if="activity.type === 'MINUTES'">
                    {{ $t("teams.completed") }}
                    {{ activity.name.toLowerCase() }}
                    {{ activity.activity_quantity }}
                    {{ activity.activity_unit_name }}
                  </span>
                  <span class="text-terciary" v-else-if="activity.type === 'STEPS'">
                    {{ $t("teams.tracked") }} {{ activity.activity_quantity }}
                    {{ activity.name.toLowerCase() }}
                  </span>
                  <span class="text-terciary" v-else>
                    {{ $t("teams.completed") }} {{ activity.name }}
                  </span>
                </span>
                <p
                  class="m-0 p-0 form-control-sm text-muted font-weight-normal"
                >                  

                  {{
                    dateDiffDays(new Date(), new Date(activity.complete_dt)) - 1 ||
                      ""
                  }}
                  {{
                    `${$tc(
                      "days_ago",
                      dateDiffDays(new Date(), new Date(activity.complete_dt)) - 1
                    )}`.toLocaleLowerCase()
                  }}                          

                </p>
              </div>
            </div>
            <div class="activity_box_border"></div>
          </div>

          <!-- Functions trigger when user reaches bottom of the div -->
          <div
            v-if="TeamActivity.length"
            v-observe-visibility="ScrolledToBottom"
          ></div>
        </div>
        <div
          v-else
          class="d-flex align-items-center justify-content-center"
          style="height:250px"
        >
          <h6 class="text-primary">{{ $t("teams.no_activities") }}</h6>
        </div>
      </b-collapse>
    </b-overlay>
  </b-card>
</template>

<script>
import { BIconChevronDown } from "bootstrap-vue"
import axios from "axios"
export default {
  props: {
    teamId: String,
  },
  components: { BIconChevronDown },

  data() {
    return {
      loading: false,
      TeamActivity: [],
      links: null,
    }
  },
  methods: {
    async getTeamActivity(pageURL) {
      this.loading = true
      const teamId = this.teamId
      let url = `/team/activity/${teamId}`
      if (pageURL) {
        url = pageURL
      }

      try {
        const response = await axios.get(url)
        this.TeamActivity = [
          ...this.TeamActivity,
          ...(response.data?.data ?? []),
        ]

        this.links = response.data?.links ?? null
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.loading = false
      }
    },

    dateDiffDays(date1, date2) {
      return Math.ceil(Math.abs(date1 - date2) / (1000 * 60 * 60 * 24))
    },  


    ScrolledToBottom(isVisible) {
      if (!isVisible) {
        return
      }
      if (this.links.more_pages) {
        this.getTeamActivity(this.links.next_page_url)
      }
    },
  },

  created() {
    this.getTeamActivity()
  },
}
</script>

<style scoped>
/* Activity Box */
.activity_box {
  box-shadow: 0px 3px 20px #2422611c;
}
.activity_box_border {
  border-bottom: 1px solid #B0BFBF;
}

.collapse-container {
  min-height: 250px;
  height: 100%;
  max-height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background: #b6b8bf;
  border-radius: 4px;
}

.text-box ,
.text-boxspan {
  font-size: 14px;
}
</style>
