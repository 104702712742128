<template>
  <b-card class="p-0 pb-2" style="min-height: 300px;">
    <h4
      class="text-terciary m-0 pt-4 px-4 "
      :class="[myTeam === 'Y' ? 'mb-0' : 'mb-3']"
    >
      {{ $t("teams.leaderboard") }}
    </h4>
    <div
      class="px-4 mb-3 d-flex align-items-center justify-content-end"
      v-if="myTeam === 'Y'"
    >
      <span class="text-terciary font-weight-normal m-0 size">
        {{ $t("remove") }}
      </span>
    </div>
    <div class="leaderboard_box_border mx-4"></div>

    <div v-if="campaignGroupUser && campaignGroupUser.length">
      <div
        class="px-4 py-3 user_wrapper "
        v-for="user in campaignGroupUser"
        :key="user.id"
      >
        <div class="d-flex">
          <b-img-lazy
            class="rounded-circle mr-2"
            style="object-fit: cover;"
            height="29"
            v-if="user.user_profile.media_file"
            width="29"
            :src="
              user.user_profile.media_file.file_path +
                user.user_profile.media_file.file_name
            "
            alt="Image"
          />

          <!-- If Media object is null -->
          <b-avatar
            v-else
            class="mr-2 avatar-circle"
            :text="user.user_profile.initials"
            size="29"
          ></b-avatar>

          <div class="d-flex flex-grow-1 text-box">
            <span class="font-weight-normal text-terciary size">
              {{ user.user_profile.full_name }}
            </span>
            <span class=" font-weight-normal text-terciary size  ">
              {{ `${Math.trunc(user.points_balance)} p`.toLocaleLowerCase() }}
            </span>
          </div>

          <!-- Delete User Modal -->
          <div class="ml-2" v-if="myTeam === 'Y'">
            <RemoveUserModal :id="user.user_id" @getTeam="getTeam" />
          </div>
        </div>
      </div>
    </div>

    <!-- If No user is present -->
    <div
      v-else
      style="height:150px"
      class=" d-flex justify-content-center align-items-center"
    >
      <h6 class="text-primary">
        {{ $t("teams.no_team_members") }}
      </h6>
    </div>
  </b-card>
</template>

<script>
import RemoveUserModal from "@/components/Checkout_Team/RemoveUserModal.vue"
export default {
  props: ["campaignGroupUser", "myTeam"],
  components: {
    RemoveUserModal,
  },
  data() {
    return {}
  },
  methods: {
    getTeam() {
      this.$emit("getTeamLeaderBoard")
    },
  },
}
</script>

<style scoped>
.leaderboard_box >>> .card {
  box-shadow: 0px 3px 20px #2422611c;
}
.leaderboard_box >>> .card-body {
  padding: 0;
}
.leaderboard_box_border {
  border-bottom: 1px solid #B0BFBF;
}

.user_wrapper:nth-child(even) > div span {
  color: #545454 !important;
}
.user_wrapper:nth-child(even) > div .avatar-circle {
  background-color: #00C782 !important;
  color: #ffffff !important;
}
.user_wrapper:nth-child(even) {
  background-color: #eeeeee;
}
.size {
  font-size: 13px;
}
.text-box {
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 360px) {
  .text-box {
    flex-direction: column;
  }
}
@media (min-width: 800px) {
  .size {
    font-size: 14px;
  }
}
</style>
