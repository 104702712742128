<template>
  <b-overlay :show="loading">
    <div>
      <b-container class="my-2 my-sm-5" fluid>
        <b-row>
          <b-col lg="8" md="12">
            <b-row align-v="center">
              <b-col xl="8" md="12" class="d-flex align-items-center ">
                <b-img-lazy
                  v-if="
                    TeamInfo.media_file &&
                      TeamInfo.media_file.file_path &&
                      TeamInfo.media_file.file_name
                  "
                  class="rounded-circle mr-4"
                  height="96px"
                  width="96px"
                  :src="
                    `${TeamInfo.media_file.file_path}${TeamInfo.media_file.file_name}`
                  "
                  alt="Team Img"
                />

                <h1 class="mb-0">{{ TeamInfo.name }}</h1>
              </b-col>

              <b-col
                xl="auto"
                md="12"
                cols="auto"
                class="d-flex justify-content-xl-end mt-3 mt-sm-0"
              >
                <b-button
                  style="border-radius:11px"
                  class=" ml-auto"
                  variant="primary"
                  id="show-btn"
                  v-if="TeamInfo.my_team === ''"
                  @click="joinTeam()"
                >
                  {{ `${$t("teams.join_team")}`.toLocaleUpperCase() }}
                </b-button>
                <b-button
                  class=" ml-auto"
                  style="border-radius:11px"
                  variant="primary"
                  id="show-btn"
                  @click="deleteTeam()"
                  v-if="TeamInfo.my_team === 'Y'"
                >
                  {{ $t("teams.leave_team") }}
                </b-button>
              </b-col>

              <b-col
                xl="auto"
                md="12"
                cols="auto"
                class="d-flex justify-content-xl-end mt-3 mt-sm-1"
                v-if="(TeamInfo.campaign_group_user || {}).length === 0"
              >
                <b-button
                  class="ml-auto"
                  variant="outline-primary"
                  id="remove-btn"
                  @click="removeTeam()"
                >
                  {{ $t("teams.delete_team") }}
                </b-button>
              </b-col>

              <b-col
                xl="auto"
                md="12"
                cols="auto"
                class="d-flex justify-content-xl-end mt-3 mt-sm-0"
              >
                <div class="mt-2 ml-2">
                  <ChangeTeamModal
                    :TeamInfo="TeamInfo"
                    :TeamId="TeamId"
                    @nameChanged="getTeam"
                  />
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
      <b-container class="my-3 my-sm-5" fluid>
        <b-row>
          <b-col xl="8" md="12" class="">
            <CommunicationBox :teamId="TeamId" />
          </b-col>

          <b-col xl="4" md="12" class=" rounded-xl leaderboard_box margin-md">
            <b-row class="margin-md">
              <b-col cols="12">
                <LeaderBoard
                  :campaignGroupUser="TeamInfo.campaign_group_user"
                  @getTeamLeaderBoard="getTeam"
                  :myTeam="TeamInfo.my_team"
                />
              </b-col>

              <b-col cols="12" class="mt-5">
                <ActivityFeed :teamId="TeamId" />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </b-overlay>
</template>

<script>
import axios from "axios"
import ActivityFeed from "@/components/Checkout_Team/ActivityFeed.vue"
import CommunicationBox from "@/components/Checkout_Team/CommunicationBox.vue"
import LeaderBoard from "@/components/Checkout_Team/LeaderBoard.vue"
import ChangeTeamModal from "@/components/Checkout_Team/ChangeTeamModal.vue"
import { mapGetters } from "vuex"
export default {
  components: {
    ActivityFeed,
    CommunicationBox,
    LeaderBoard,
    ChangeTeamModal,
  },
  data() {
    return {
      TeamId: "",
      TeamInfo: {},

      loading: false,
    }
  },

  computed: {
    ...mapGetters({ userId: "userInfo" }),
  },

  methods: {
    async removeTeam() {
      await axios.delete("/team", {
        params: { team_id: this.TeamId },
      })
      this.$router.replace("/teams")
    },
    async getTeam() {
      this.loading = true
      const teamId = this.TeamId
      try {
        const response = await axios.get(
          `/team?group_id=${encodeURIComponent(teamId)}`
        )
        this.TeamInfo = response.data?.data[0] ?? {}
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.loading = false
      }
    },
    async joinTeam() {
      const teamId = this.TeamId
      try {
        let data = {
          team_id: teamId,
        }
        const response = await axios.post(`/team/join`, data)

        if (response.status == 200) {
          this.$store.dispatch("addToastMessage", {
            type: "success",
            text: "You joined the team",
          })
          this.getTeam()
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      }
    },
    async deleteTeam() {
      if (this.userId) {
        const userID = this.userId.user_id

        try {
          let data = {
            user_id: userID,
          }

          const response = await axios.delete(`/team/leave`, {
            params: data,
          })
          if (response.status === 200) {
            this.$store.dispatch("addToastMessage", {
              type: "success",
              text: "You left the team",
            })
            this.getTeam()
          }
        } catch ({ response }) {
          this.$store.dispatch("addToastMessage", {
            type: "danger",
            text: response.data.error_message,
          })
        }
      }
    },
  },

  created() {
    this.TeamId = this.$route.params.id

    this.getTeam().then(() => {
      if (this.TeamInfo.my_team === "Y") {
        this.$store.dispatch("setOwnTeam", this.TeamId)
      }
    })
    this.$store.dispatch("fetchUserInfo")
  },
}
</script>

<style scoped>
@media (max-width: 1196px) {
  .margin-md {
    margin-top: 25px !important;
  }
}
</style>
