<template>
  <span>
    <b-button
      variant="outline-primary"
      v-if="TeamInfo.my_team === 'Y'"
      class="p-2 text-nowrap"
      v-b-modal.change-team      
    >
      {{ $t("teams.change_team_name") }}
    </b-button>
    <b-modal
      id="change-team"
      @ok="changeTeamName"
      @show="newTeamName = TeamInfo.name"
      :title="$t('teams.change_team_name')"
    >
      <b-form-group
        :label="`${$t('teams.new_team_name')}:`"
        label-for="newTeamName"
      >
        <b-input
          id="newTeamName"
          name="newTeamName"
          type="text"
          v-model="newTeamName"
          required
        />
      </b-form-group>
    </b-modal>
  </span>
</template>

<script>
import axios from "axios"
export default {
  props: { TeamInfo: Object, TeamId: String },
  data() {
    return {
      newTeamName: "",
    }
  },
  methods: {
    async changeTeamName() {
      try {
        await axios.put("/team/update", {
          team_id: this.TeamId,
          team_name: this.newTeamName,
        })
        this.$emit("nameChanged")
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      }
    },
  },
}
</script>
