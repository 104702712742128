<template>
  <b-overlay :show="loading">
    <div class="tooltip-wrapper">
      <div v-if="users.length">
        <div class="mb-2" v-for="(user, index) in users" :key="index">
          <b-img-lazy
            class="rounded-circle mr-2"
            style="object-fit: cover;"
            height="19"
            width="19"
            v-if="user.avatar_file_path"
            :src="user.avatar_file_path + user.avatal_file_name"
            alt="Image"
          />

          <span class="font-weight-normal  ">{{ user.full_name }}</span>
        </div>
      </div>

      <!-- If No user is present -->
      <div
        v-else
        style="height:50px"
        class="d-flex justify-content-center align-items-center"
      >
        <p class="text-primary">{{ $t("teams.no_team_members") }}</p>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import axios from "axios"
export default {
  name: "Tooltip",
  props: ["communication_id", "reaction_id"],
  data() {
    return {
      loading: false,
      users: [],
    }
  },
  methods: {
    async getReactedUsers() {
      this.loading = true
      const reaction_id = this.reaction_id
      const communication_id = this.communication_id
      try {
        let data = {
          communication_id: communication_id,
          reaction_id: reaction_id,
        }
        const response = await axios.get(`/communicationreaction`, {
          params: data,
        })
        this.users = response.data?.data ?? []
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.loading = false
      }
    },
  },

  created() {
    this.getReactedUsers()
  },
}
</script>

<style scoped>
.tooltip-wrapper {
  min-height: 80px;
  background: transparent;
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-thumb {
  background: #b6b8bf;
  border-radius: 4px;
}
</style>
